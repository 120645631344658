import React, { FC } from 'react';
import { Seo } from '../components';
import { PageProps } from 'gatsby';
import { PageLayout } from '../components';
import config from '../../app-config.json';
import * as s from '../style.pages/index.style';
import { Typography } from '@material-ui/core';
import { siteMetadata } from '../../gatsby-config';
// import { UserDetails } from '../../components/Form';
// import { Typography } from '@material-ui/core';


export const PAGE_TITLE = config.pages.CONTACT.title;
const { email, members, officePhNo } = config.pages.CONTACT.details;

//////////////////////////////////  component ////////////////////////////////
const Contact: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} />
            <s.BodyWrap >
                <Typography variant='h2' >Contact Us</Typography>
                <hr />
                <br /><br />
                <Typography gutterBottom ><b>Where to find us:</b> Mohammedi Park, Rowdell Road, Northolt, UB5 6AG, United Kingdom</Typography>
                <Typography gutterBottom ><b>Call us:</b> <a href={`tel:${officePhNo}`}>{officePhNo}</a></Typography>
                <Typography gutterBottom ><b>E-mail:</b> <a href={`mailto:${email}`}>{email}</a></Typography>
                <br />
                <Typography gutterBottom variant='h5' >For any questions or information please contact:</Typography>
                {members.map(({ name, phoneNo }, idx) => {
                    return <Typography key={idx} gutterBottom ><b>{name}:</b> <a href={`tel:${phoneNo}`}>{phoneNo}</a></Typography>
                })
                }
                <br /><br />
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.389705795915!2d-0.36721198422871615!3d51.542752679641005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487612c5860fcb6f%3A0x1796b1fca46ee337!2sMohammedi%20Park%20Masjid%20Complex!5e0!3m2!1sen!2s!4v1612979014422!5m2!1sen!2s" width="100%" height="450" style={{ border: '3px solid black' }} aria-hidden="false"></iframe>
                <br /><br /><br />
            </s.BodyWrap>
        </PageLayout>
    )
}

export default Contact;
